export default {
	ADD_GUEST_TRAVELLER_CLICKED: 'Add Guest Traveller Clicked',
	ABANDON_BOOKING_FROM_NAV: 'Abandon Booking From Nav',
	BOOKING_SUBMIT: 'Booking Submit',
	BREAK_POLICY_WINDOW_ACTIONED: 'Break Policy Window Actioned',
	CART_ITEM_REMOVED: 'Cart Item Removed',
	CART_CONTINUE_CLICKED: 'Continue Clicked',
	CART_SKIP_CLICKED: 'Skip Clicked',

	DUPLICATE_BOOKINGS_FOUND: 'Duplicate bookings found.',

	CAR_RESULTS_FILTER_ACTIONED: 'Car Results Filter Actioned',
	CAR_RESULTS_SORT_ACTIONED: 'Car Results Sort Actioned',

	CHANGE_BOOKING_CLICKED: 'Change Booking Clicked',
	CHANGE_BOOKING_BACK_CLICKED: 'Change Booking Back Clicked',
	CHANGE_BOOKING_SEARCH: 'Change Booking Search',
	CHANGE_BOOKING_COMPLETE: 'Change Booking Complete',
	CHANGE_BOOKING_RESULTS: 'Change Booking Results',

	FLIGHT_FARE_DETAILS_CLICKED: 'Flight Fare Details Clicked',
	FLIGHT_FARE_SELECTED: 'Flight Fare Selected',
	FLIGHT_RESULTS_LOAD_MORE_CLICKED: 'Flight Results Load More Clicked',
	FLIGHT_RESULTS_SORT_ACTIONED: 'Flight Results Sort Actioned',
	FLIGHT_RESULTS_FILTER_ACTIONED: 'Flight Results Filter Actioned',
	FLIGHT_RESULTS_TIME_RANGE_FILTER_ACTIONED: 'Flight Results Time Range Filter Actioned',
	FLIGHT_RESULTS_CHEAPEST_FILTER_ACTIONED: 'Flight Results Cheapest Filter Actioned',
	FLIGHT_TRIP_DETAILS_CLICKED: 'Flight Trip Details Clicked',
	FLIGHT_TAB_CLICKED: 'Flight Tab Clicked',

	HOTEL_TAB_CLICKED: 'Hotel Tab Clicked',
	HOTEL_RESULTS_TAB_CLICKED: 'Hotel Results Tab Clicked',
	HOTEL_RESULTS_VIEW_CLICKED: 'Hotel Results View Clicked',
	HOTEL_RATE_SELECTED: 'Hotel Rate Selected',
	HOTEL_RESULTS_FILTER_ACTIONED: 'Hotel Results Filter Actioned',
	HOTEL_PAYMENT_OPTION_WINDOW_ACTIONED: 'Hotel Payment Option Window Actioned',
	HOTEL_RESULTS_SEARCH_FILTER_ACTIONED: 'Hotel Results Search Filter Actioned',
	HOTEL_UNAVAILABLE_RESULTS_CLICKED: 'Hotel Unavailable Results Clicked',
	HOTEL_RESULTS_SORT_ACTIONED: 'Hotel Results Sort Actioned',
	HOTEL_RESULTS_MAP_SIDEBAR_ACTIONED: 'Hotel Results Map Sidebar Actioned',

	GUEST_TRAVELLER_SAVED: 'Guest Traveller Saved',
	LOGIN: 'Login',
	PAGE_VIEW: 'Page View',
	POI_SEARCH_ITEM_SELECTED: 'POI Search Item Selected',
	RECENT_SEARCH_SELECTED: 'Recent Search Selected',
	RECENT_SEARCHES_CLEARED: 'Recent Searches Cleared',
	RECENT_SEARCHES_PAGE_CLICKED: 'Recent Searches Page Clicked',
	SEARCH: 'Search',
	SEARCH_UPDATED: 'Search Updated',
	TIMED_OUT: 'Timed Out',

	BOOKING_APPROVAL_ACTIONED: 'Booking Approval Actioned',
	BOOKING_TAB_CLICKED: 'Booking Tab Clicked',
	BOOKING_ADD_TO_CALENDAR_CLICKED: 'Booking Add To Calendar Clicked',
	BOOKING_SELECT_SEAT_CLICKED: 'Booking Select Seat Clicked',
	BOOKING_SEARCH_ACTIONED: 'Booking Search Actioned',
	BOOKING_RESULTS_FILTER_ACTIONED: 'Booking Results Filter Actioned',
	BOOKING_RESULTS_PAGE_SELECTED: 'Booking Results Page Selected',
	BOOKING_SELECTED: 'Booking Selected',

	ITINERARY_SECTOR_ADDED: 'Itinerary Sector Added',
	ITINERARY_SECTOR_REMOVED: 'Itinerary Sector Removed',
	ITINERARY_BAGGAGE_SELECTED: 'Itinerary Baggage Selected',
	ITINERARY_FARE_SWAPPED: 'Itinerary Fare Swapped',
	ITINERARY_APPROVER_SELECTED: 'Itinerary Approver Selected',
	ITINERARY_HOTEL_PROMPT_DISPLAYED: 'Itinerary Hotel Prompt Displayed',
	ITINERARY_HOTEL_PROMPT_ACTIONED: 'Itinerary Hotel Prompt Actioned',

	CONFIRMATION_LINK_CLICKED: 'Confirmation Link Clicked',
	CONFIRMATION_MANAGE_BOOKINGS_CLICKED: 'Confirmation Manage Bookings Clicked',

	PAYMENT_TEMP_CARD_ADDED: 'Payment Temp Card Added',

	ACCOUNT_CREATED: 'Account Created',
	FORGOT_PASSWORD_ACTIONED: 'Forgot Password Actioned',
	ONBOARDING_CARD_CLICKED: 'Onboarding Card Clicked',
	INVITE_ACTIONED: 'Invite Actioned',
	UPDATE_USER_STATUS: 'Update User Status',
	UPDATE_USER_PROFILE: 'Update User Profile',
	UPDATE_USER_MEMBERSHIPS: 'Update User Memberships',
	ADD_PAYMENT_METHOD: 'Add Payment Method',
	UPDATE_COMPANY_PROFILE: 'Update Company Profile',
	UPDATE_COMPANY_MEMBERSHIPS: 'Update Company Memberships',
	UPDATE_ALLOW_GUESTS_RULE: 'Update Allow Guests Rule',
	UPDATE_RESTRICT_CLASS_TYPE_RULE: 'Update Restrict Class Type Rule',
	UPDATE_COMPANY_REFERENCE_RULE: 'Update Company Reference Rule',
	SEND_PASSWORD_RESET: 'Send Password Reset',
	REGISTRATION_PROFILE_UPDATED: 'Registration Profile Updated',
	REGISTRATION_PAYMENT_METHOD_ADDED: 'Registration Payment Method Added',
	REGISTRATION_INVITE_SENT: 'Registration Invite Sent',
	REGISTRATION_SKIPPED: 'Registration Skipped',

	AIRPORT_TRANSFERS_BANNER_DISPLAYED: 'Airport Transfers Banner Displayed'
};
