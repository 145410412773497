import { formatForApiDate } from '$lib/util/date.js';
import sortBy from 'lodash-es/sortBy';
import some from 'lodash-es/some';
import filter from 'lodash-es/filter';
import findKey from 'lodash-es/findKey';
import flatten from 'lodash-es/flatten';

export {
	approvalStates,
	addPaymentMethodsFromGroupsToItinerary,
	itineraryHasApproval,
	itineraryHasPreTripApproval,
	itineraryHasOnRequestHotel,
	itinerarySectorTypes,
	itinerarySearchTripType,
	setSearchChanges,
	groupSectorsByDate,
	groupSectorsForPayment,
	passengerHasApproval,
	passengerHasPreTripApproval,
	priceSummary,
	paymentCardName,
	airlineFeeTotal,
	flightChangeTotal,
	handleTextarea
};

function passengerHasApproval(pax) {
	const approvalRequiredLevels = ['Passive', 'PreTrip'];
	return approvalRequiredLevels.includes(pax.ApprovalLevel);
}

function passengerHasPreTripApproval(pax) {
	const approvalRequiredLevels = ['PreTrip'];
	return approvalRequiredLevels.includes(pax.ApprovalLevel);
}

function passengerHasOnRequestHotel(pax) {
	return pax.Hotels.find((hotel) => hotel.IsUnlimitedAllotment);
}

function itineraryHasApproval(itinerary) {
	return some(itinerary.Passengers, (pax) => {
		return passengerHasApproval(pax);
	});
}

function itineraryHasPreTripApproval(itinerary) {
	return some(itinerary.Passengers, (pax) => {
		return passengerHasPreTripApproval(pax);
	});
}

function itineraryHasOnRequestHotel(itinerary) {
	return some(itinerary.Passengers, (pax) => {
		return passengerHasOnRequestHotel(pax);
	});
}

function itinerarySectorTypes(itinerary) {
	const sectorTypesInItinerary = [];

	if (itinerary.Flights.length > 0) {
		sectorTypesInItinerary.push('flight');
	}

	if (itinerary.Hotels.length > 0) {
		sectorTypesInItinerary.push('hotel');
	}

	if (itinerary.Cars.length > 0) {
		sectorTypesInItinerary.push('car');
	}

	return sectorTypesInItinerary;
}

function itinerarySearchTripType(itinerary) {
	let travelMode = 'Land Only';
	let travelDirection = '';

	if (itinerary.Search.Flights.length) {
		travelMode = 'Flight';

		if (itinerary.Search.Flights.length === 1) {
			travelDirection = 'One-way';
		}

		if (itinerary.Search.Flights.length > 1) {
			travelDirection = 'Multi-leg';
		}

		if (
			itinerary.Search.Flights.length === 2 &&
			itinerary.Search.Flights[0].Origin.Code === itinerary.Search.Flights[1].Destination.Code
		) {
			travelDirection = 'Return';
		}
	}

	return `${travelMode} ${travelDirection}`;
}

function setSearchChanges(args) {
	const { itinerary, searchData, sectorType } = args;
	const itineraryWithSearchChanges = Object.assign({}, itinerary);

	if (sectorType === 'flights') {
		let flights = [];

		Object.values(searchData).forEach((item) => {
			let sector = item.sector;

			flights.push({
				ParentIdentifier: sector.Identifier,
				Date: formatForApiDate(`${item.date} ${item.time}`, 'DD/MM/YYYY h:mmA'),
				Destination: { Code: sector.Arrival.Code },
				Origin: { Code: sector.Departure.Code }
			});
		});

		itineraryWithSearchChanges.Search.Flights = sortBy(flights, 'Date');
		itineraryWithSearchChanges.Search.Hotels = [];
		itineraryWithSearchChanges.Search.SearchTypes = ['Flight'];
	}

	if (sectorType === 'hotels') {
		let hotels = [];

		Object.values(searchData).forEach((item) => {
			let sector = item.sector;

			hotels.push({
				ParentIdentifier: sector.Identifier,
				DateFrom: formatForApiDate(item.checkIn, 'DD/MM/YYYY'),
				DateTo: formatForApiDate(item.checkOut, 'DD/MM/YYYY'),
				Location: sector.Location,
				HotelId: sector.HotelId
			});
		});

		itineraryWithSearchChanges.Search.Hotels = sortBy(hotels, 'DateFrom');
		sessionStorage.setItem(
			'change:HotelIds',
			JSON.stringify(itineraryWithSearchChanges.Search.Hotels.map((hotel) => hotel.HotelId))
		);
		itineraryWithSearchChanges.Search.Flights = [];
		itineraryWithSearchChanges.Search.SearchTypes = ['Hotel'];
	}

	return itineraryWithSearchChanges;
}

function groupSectorsByDate(travellerItinerary, IsPostBookingSegment, Status, flightsOnly) {
	if (!travellerItinerary) return undefined;
	const groupedSectors = [];
	const filter_sector = (s) => IsPostBookingSegment ? s?.IsPostBookingSegment && s?.IsActivePostBookingSegment && (Status ? s?.Status === Status : true) : true;
	travellerItinerary.Flights.filter(filter_sector).forEach((sector) => {
		if (sector.Status != 'Cancelled')
			addToGroup(sector.Departure.Date, sector.Arrival.Date, sector, 'flight');
	});


	if (!flightsOnly) {
	travellerItinerary.Hotels.filter(filter_sector).forEach((sector) => {
		if (sector.Status != 'Cancelled') addToGroup(sector.DateFrom, sector.DateTo, sector, 'hotel');
	});

	travellerItinerary.Cars.filter(filter_sector).forEach((sector) => {
		if (sector.Status != 'Cancelled')
			addToGroup(sector.PickUp.Date, sector.DropOff.Date, sector, 'car');
	});

	if (!IsPostBookingSegment) {
		if (travellerItinerary.Shuttles)
			travellerItinerary.Shuttles.forEach((sector) => {
				addToGroup(sector.PickupTime, sector.DropoffTime, sector, 'shuttle');
			});

		if (travellerItinerary.Taxis)
			travellerItinerary.Taxis.forEach((sector) => {
				if (sector.Status === 'Booked')
					addToGroup(sector.PickUpTime, sector.DropOffTime, sector, 'taxi');
			});
	}
	}

	function formatToGroupDate(date) {
		return `${date.substr(0, 10)}T00:00`;
	}

	function addToGroup(startDate, endDate, sector, sectorType) {
		const groupDate = formatToGroupDate(startDate);

		if (sectorType === 'shuttle') {
			startDate = startDate + '+12:00';
		}

		const groupSector = Object.assign(
			{},
			{
				date: sectorType === 'hotel' ? `${startDate.substr(0, 10)}T23:59` : startDate,
				endDate,
				sectorType,
				data: sector
			}
		);

		const group = groupedSectors.find((grouped) => {
			return grouped.date === groupDate;
		});

		if (group) {
			group.sectors.push(groupSector);
		} else {
			groupedSectors.push({
				date: groupDate,
				sectors: [groupSector]
			});
		}
	}

	groupedSectors.map((sectorGrp) => {
		sectorGrp.sectors = sectorGrp.sectors
			.sort((a, b) => new Date(b.date) - new Date(a.date))
			.reverse();
	});

	return sortBy(groupedSectors, (group) => {
		return group.date;
	});
}

function paymentMethodsForGroup(paymentMethods, groupIdentifier) {
	const filteredPaymentMethods = paymentMethods.filter((paymentMethod) => {
		return paymentMethod.SectorGroups.includes(groupIdentifier);
	});

	return filteredPaymentMethods.map((paymentMethod) => {
		// paymentMethod.Payment.IsPaymentOptionLocked = paymentMethod.IsPaymentOptionLocked;
		return paymentMethod.Payment;
	});
}

function sectorGroups(itinerary, pendingSectors, sectorsGroupsForPayment, paymentMethods) {
	pendingSectors.forEach((s) => {
		const sectorGroup = sectorsGroupsForPayment.find((group) => {
			return group.groupIdentifier === s.GroupIdentifier;
		});

		if (sectorGroup) {
			sectorGroup.sectors.push(s);
		} else {
			let preSelectedPaymentMethod = undefined;

			const activePaymentMethodForGroup =
				itinerary.Passengers[0].Configurations.PaymentMethod.PaymentItems.find((paymentItem) => {
					return paymentItem.SectorGroupIdentifier === s.GroupIdentifier;
				});

			if (activePaymentMethodForGroup) {
				preSelectedPaymentMethod =
					activePaymentMethodForGroup.Card || activePaymentMethodForGroup.Invoice;
			}

			if (!preSelectedPaymentMethod) {
				const invoiceForGroup = paymentMethods.find((paymentMethod) => {
					const isInvoice = paymentMethod.Payment.PaymentType === 'Invoice';
					const isGroup = paymentMethod.SectorGroups.includes(s.GroupIdentifier);
					return isInvoice && isGroup;
				});

				if (invoiceForGroup) {
					preSelectedPaymentMethod = invoiceForGroup.Payment;
				}
			}

			sectorsGroupsForPayment.push({
				groupIdentifier: s.GroupIdentifier,
				sectors: [s],
				selectedPaymentMethod: preSelectedPaymentMethod,
				paymentMethods: paymentMethodsForGroup(paymentMethods, s.GroupIdentifier)
			});
		}
	});

	return sectorsGroupsForPayment;
}

function groupSectorsForPayment(itinerary, paymentMethods, args = { land_segments: false, all_segments: false, pax_index: 0, add_baggage: false }) {
	let sectorsGroupsForPayment = [];
	if (args.land_segments) {
		const pendingHotels = itinerary.Passengers[args.pax_index].Hotels.filter((hotel) => {
			return hotel.Status === 'Pending' && hotel.IsPostBookingSegment;
		});
		const pendingCars = itinerary.Passengers[args.pax_index].Cars.filter((car) => {
			return car.Status === 'Pending' && car.IsPostBookingSegment;
		});

		sectorsGroupsForPayment = sectorGroups(itinerary, [...pendingHotels, ...pendingCars], sectorsGroupsForPayment, paymentMethods);
	} else if (args.all_segments) {

		const pendingFlights = itinerary.Passengers[args.pax_index].Flights.filter((flight) => {
			return flight.Status === 'Pending';
		});

		const pendingHotels = itinerary.Passengers[args.pax_index].Hotels.filter((hotel) => {
			return hotel.Status === 'Pending'
		});
		const pendingCars = itinerary.Passengers[args.pax_index].Cars.filter((car) => {
			return car.Status === 'Pending'
		});

		sectorsGroupsForPayment = sectorGroups(itinerary, [...pendingFlights, ...pendingHotels, ...pendingCars], sectorsGroupsForPayment, paymentMethods);
	} else if (args.add_baggage) {
		const flights = itinerary.Passengers[0].Flights.filter((flight) => {
			return flight.AncillaryServices.some((service) => service.Type === 'Baggage');
		});

		sectorsGroupsForPayment = sectorGroups(itinerary, [...flights], sectorsGroupsForPayment, paymentMethods);
	} else {
		const pendingFlights = itinerary.Passengers[0].Flights.filter((flight) => {
			return flight.Status === 'Pending';
		});

		sectorsGroupsForPayment = sectorGroups(itinerary, pendingFlights, sectorsGroupsForPayment, paymentMethods);
	}

	return sectorsGroupsForPayment;
}

const approvalStates = {
	Auto: {
		id: 'auto',
		message: 'Approved',
		class: 'approvalApproved' //#48c761
	},
	Pending: {
		id: 'pending',
		message: 'Awaiting Approval',
		class: 'approvalPending' //#ff8a05
	},
	Approved: {
		id: 'approved',
		message: 'Approved',
		class: 'approvalApproved' //#48c761
	},
	Declined: {
		id: 'denied',
		message: 'Denied',
		class: 'approvalDenied' //#ff274b
	},
	Expired: {
		id: 'expired',
		message: 'Expired',
		class: 'approvalExpired' //#2C3E50
	}
};

function priceSummary(itinerary, sectorGroup) {
	const priceTotals = {
		Flight: [],
		Hotel: [],
		Car: [],
		Seat: [],
		flights: 0,
		hotels: 0,
		cars: 0,
		seats: 0
	};

	const sectors = flatten(
		sectorGroup.map((group) => {
			const _results = [];

			group.sectors.forEach((sector) => {
				const sectorObj = sector.data;
				sectorObj.sectorType = sector.sectorType;

				_results.push(sectorObj);

				let recursivePosition = sectorObj.Parent;
				let hasParent = recursivePosition;

				while (hasParent) {
					if (recursivePosition) {
						recursivePosition.sectorType = sectorObj.sectorType;
						_results.push(recursivePosition);
						recursivePosition = recursivePosition.Parent;
					} else {
						hasParent = false;
					}
				}
			});

			return _results;
		})
	);

	itinerary.Configurations.PaymentMethod.PaymentItems.forEach((paymentMethod) => {
		paymentMethod = addSectorsItemsToPaymentMethod(paymentMethod, sectors);

		if (paymentMethod.Invoice) {
			priceTotals[paymentMethod.Type].push(paymentMethod.Invoice);
		} else {
			priceTotals[paymentMethod.Type].push(paymentMethod.Card);
		}
	});

	sectors.forEach((sector) => {
		if (sector.sectorType === 'flight') {
			priceTotals.flights += sector.PayableAmount;
		}

		if (sector.sectorType === 'hotel') {
			priceTotals.hotels += sector.TotalAmount;
		}

		if (sector.sectorType === 'car') {
			priceTotals.cars += sector.TotalAmount;
		}
	});

	return priceTotals;
}

function paymentCardName(type) {
	let cardName = '';

	switch (type) {
		case 'VI':
			cardName = 'Visa';
			break;
		case 'CA':
			cardName = 'Mastercard';
			break;
		case 'AX':
			cardName = 'American Express';
			break;
		case 'DC':
			cardName = 'Diners';
			break;
		default:
			cardName = '';
	}

	return cardName;
}

function addPaymentMethodsFromGroupsToItinerary(itinerary, paymentGroups) {
	itinerary.Passengers.forEach((pax) => {
		pax.Configurations.PaymentMethod.PaymentItems = paymentGroups.map((groupForPayment) => {
			if (!groupForPayment.selectedPaymentMethod) return;
			const paymentType = groupForPayment.selectedPaymentMethod.PaymentType;

			return {
				Card: paymentType === 'Card' ? groupForPayment.selectedPaymentMethod : null,
				Invoice: paymentType === 'Invoice' ? groupForPayment.selectedPaymentMethod : null,
				SectorGroupIdentifier: groupForPayment.groupIdentifier,
				SectorIdentifier: '',
				Type: 'Flight'
			};
		});
	});

	return itinerary;
}

function airlineFeeTotal(itinerary) {
	let total = 0;

	itinerary.Passengers.forEach((pax) => {
		pax.Flights.forEach((flight) => {
			if (flight.Status === 'Pending') {
				total += flight.Fees.reduce((feesTotal, fee) => {
					return feesTotal + (fee.Type === 'AirlineFee' ? fee.Amount : 0);
				}, 0);
			}
		});
	});

	return total;
}

function flightChangeTotal(itinerary) {
	let total = 0;

	itinerary.Passengers.forEach((pax) => {
		pax.Flights.forEach((flight) => {
			if (flight.Status === 'Pending') {
				total += flight.Amount;
			}
		});
	});

	return total;
}

function handleTextarea({event, callback, max_length=255}) {
	let input_text = event.target.value;
	input_text = input_text.replace(/–/g, '-').replace(/[^a-zA-Z0-9 .*-]/g, '');
	if (input_text.length > max_length) {
		input_text = input_text.substring(0, max_length);
	}
	callback(input_text.trimStart());
}