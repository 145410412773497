import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export function parseDate(dateTime, format) {
	return dayjs(dateTime, format);
}

export function formatDate(dateTime, format) {
	if (!dateTime || !format) return '-';
	return parseDate(dateTime, 'YYYY-MM-DDTHH:mm').format(format);
}

export function formatFromApiDate(dateTime, format) {
	if (!dateTime || !format) return '-';
	return parseDate(dateTime, 'YYYY-MM-DDTHH:mm').format(format);
}

export function formatForApiDate(dateTime, format) {
	if (!dateTime || !format) return '-';
	return parseDate(dateTime, format).format('YYYY-MM-DDTHH:mm');
}
